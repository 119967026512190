<template>
  <section id="section-banner" class="hero">
    <div class="h-100">
      <div class="d-flex flex-column justify-content-between h-100">
        <div class="d-flex justify-content-start">
          <img v-scrollanimation="'left-long'" class="dots-left-home d-none d-md-block" src="@/assets/images/dots-left-home.png" alt="dots-image" />
        </div>
        <div class="d-flex justify-content-end">
          <img v-scrollanimation="'right-long'" class="home-trapesium align-self-end" src="@/assets/images/trapesium.png" alt="trapesium" />
        </div>
      </div>
    </div>
    <div class="nstra-container hero-content position-absolute w-100 h-md-100 banner-content">
      <div class="row h-md-100">
        <div class="col-12 col-lg-6">
          <div class="hero-left">
            <h2 class="banner-title">
              Building teams that build your software
            </h2>
            <p class="banner-text">
              Providing your organization world-class solutions in application engineering with expert technical knowledge, signature implementation methodology and proven experience spanning the
              enterprise to start-up organizations.
            </p>
            <router-link to="/our-approach">
              <b-button class="btn-banner mb-40px mb-lg-0px" variant="primary">
                Learn more
              </b-button>
            </router-link>
          </div>
        </div>
        <div class="col-12 col-lg-6 d-flex align-items-end justify-content-end position-relative">
          <img width="600" class="hero-image reguler-shadow reguler-border-radius" src="@/assets/images/home/banner-img.jpeg" alt="building teams that build your software image." />
          <img width="220" v-scrollanimation="'right-long'" class="float-dots-wrap d-none d-md-block" src="@/assets/images/dots-right-home.png" alt="dots-image" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { BButton } from "bootstrap-vue";
export default {
  name: "BannerSection",
  components: {
    BButton,
  },
};
</script>
